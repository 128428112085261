import React, { useRef, useEffect } from 'react';

const FileUpload = ({ label, onFileSelect, file, clearFile }) => {
    const fileInputRef = useRef();

    useEffect(() => {
        if (clearFile) {
            fileInputRef.current.value = '';
        }
    }, [clearFile]);

    const handleChange = (e) => {
        onFileSelect(e.target.files[0]);
    };

    return (
        <div className="file-upload">
            <label>{label}</label>
            <input type="file" onChange={handleChange} ref={fileInputRef} />
        </div>
    );
};

export default FileUpload;
