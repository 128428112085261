import React, { useState } from 'react';
import FileUpload from './FileUpload';
import './App.css';

const ComparisonForm = ({ onFormSubmit, isLoading }) => {
    const [originalPdf, setOriginalPdf] = useState(null);
    const [taggedPdf, setTaggedPdf] = useState(null);
    const [clearFileInputs, setClearFileInputs] = useState(false);

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!originalPdf || !taggedPdf) {
            alert('Please select both PDF files.');
            return;
        }
        await onFormSubmit(originalPdf, taggedPdf);
        setOriginalPdf(null);
        setTaggedPdf(null);
        setClearFileInputs(true);
        setTimeout(() => setClearFileInputs(false), 100); // Brief delay to allow clear
    };

    return (
        <form onSubmit={handleSubmit} className={isLoading ? 'blurred' : ''}>
            <div className="file-upload">
                <FileUpload
                    label="Original PDF:"
                    onFileSelect={setOriginalPdf}
                    file={originalPdf}
                    clearFile={clearFileInputs}
                />
            </div>
            <div className="file-upload">
                <FileUpload
                    label="Tagged PDF:"
                    onFileSelect={setTaggedPdf}
                    file={taggedPdf}
                    clearFile={clearFileInputs}
                />
            </div>
            <button type="submit">Compare PDFs</button>
        </form>
    );
};

export default ComparisonForm;
