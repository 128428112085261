import React, { useState } from 'react';
import './App.css';
import Form from './Form';
import FormData from "form-data";
import { sendSlackMessage } from './slackService';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const App = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [comparisons, setComparisons] = useState([]);
  const baseUrl = process.env.REACT_APP_API_URL;
  const token = process.env.REACT_APP_API_TOKEN;
  const statusMessages = {
    200: 'Comparison complete!',
    202: 'Comparison in progress...',
    404: 'Tracking ID not found.',
    500: 'An error occurred.'
  };

  const handleFormSubmit = async (originalPdf, taggedPdf) => {
    setIsLoading(true);
    const formData = new FormData();
    formData.append('original_file', originalPdf);
    formData.append('pre_tagged_file', taggedPdf);

    try {
      const response = await fetch(`${baseUrl}/conformity`, {
        method: 'POST',
        body: formData,
        headers: {
          'Authorization': `Bearer ${token}`,
        }
      });
      const data = await response.json();

      if (response.ok) {
        const newComparison = {
          trackingId: data.task_id,
          key: data.task_id,
          status: 'Checking progress...',
        };
        const newComparisons = [...comparisons, newComparison];
        setComparisons(newComparisons);
        checkProgress(data.task_id, newComparisons.length);
        toast.success('You will receive notification once comparison is complete');
      } else {
        // if response has key detail, show it
        toast.error(data.detail || 'Error starting comparison.');
      }
    } catch (error) {
      console.error('Error:', error);
      toast.error('An error occurred.');
    } finally {
      setIsLoading(false);
    }
  };

  const updateComparisonStatus = (index, status) => {
    const updatedComparisons = comparisons.map((comparison, i) =>
        i === index ? { ...comparison, status: status } : comparison
    );
    setComparisons(updatedComparisons);
  };

  const checkProgress = async (id, index) => {
    try {
      const response = await fetch(`${baseUrl}/progress/result?identifier=${id}`);
      if (response.status === 200) {
        const fileContent = await response.json();
        updateComparisonStatus(index, statusMessages[response.status]);
        await sendSlackMessage('Comparison process is done.', fileContent);
      } else if (response.status === 202) {
        updateComparisonStatus(index, statusMessages[response.status]);
        setTimeout(() => checkProgress(id, index), 30000);
      } else {
        updateComparisonStatus(index, statusMessages[response.status]);
      }
    } catch (error) {
      console.error('Error:', error);
      const updatedComparisons = comparisons.map((comparison, i) =>
          i === index ? { ...comparison, status: 'An error occurred.' } : comparison
      );
      setComparisons(updatedComparisons);
    }
  };

  return (
      <div className="App">
        <h1>PDF Comparison Tool</h1>
        <div style={{ position: 'relative', display: 'inline-block', width: '100%', textAlign: 'center'}}>
          <Form onFormSubmit={handleFormSubmit} isLoading={isLoading} />
          {isLoading && (
              <div className="loading-overlay">
                <div className="spinner"></div>
                <p>Uploading files...</p>
              </div>
          )}
        </div>
        <div style={{ marginTop: '20px' }}>
          {comparisons.map((comparison, index) => (
              <p key={comparison.key}>{`Comparison ${index + 1}: ${comparison.status}`}</p>
          ))}
        </div>
        <ToastContainer />
      </div>
  );
};

export default App;
