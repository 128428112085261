export const sendSlackMessage = async (message, fileContent) => {
    try {
        const response = await fetch('/api/send-slack-message', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ message, fileContent }),
        });

        if (!response.ok) {
            console.error('Error sending message to Slack:', response.statusText);
        }
    } catch (error) {
        console.error('Error sending message to Slack:', error);
    }
};
